.DoodleCell {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 991px) {
  .DoodleCell {
  }
}

@media (max-width: 767px) {
  .DoodleCell {
  }
}

.DoodleCell svg {
  transform: scale3d(1, 1, 1);
  transform-style: preserve-3d;
  transition: transform 0.4s ease-out;
}

.DoodleCell:hover > .DoodleCell-download-bar {
  opacity: 1;
}

.DoodleCell:hover svg {
  transform: scale3d(1.1, 1.1, 1);
}

.DoodleCell-download-bar {
  transition: opacity 0.1s ease-out;
  display: flex;
  opacity: 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  justify-content: center;
}

.DoodleCell-download-botton {
  background-color: #000;
  color: #fff;
  font-weight: bold;
  padding: 8px 12px;
  border-radius: 40px;
  font-size: 12px;
  margin-right: 4px;
}

.DoodleCell-download-botton:hover {
  background-color: #6623d1;
  color: #fff;
}
