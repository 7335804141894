.SideBar {
  position: fixed;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: 0%;
  width: 264px;
  height: 100vh;
  padding: 32px 24px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  font-size: 14px;
  z-index: 100;
}

@media (max-width: 991px) {
  .SideBar {
    width: 256px;
  }
}

@media (max-width: 767px) {
  .SideBar {
    position: relative;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
    width: auto;
    height: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
}

.SideBar-logo {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 46px;
}

@media (max-width: 767px) {
  .SideBar-logo {
    font-size: 32px;
    text-align: center;
  }
}

.SideBar-logo a {
  font-weight: 700;
}

.SideBar-logo a:hover {
  color: #000;
}

.SideBar-tabs-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.SideBar-tab-container {
  min-height: 178px;
  position: relative;
}

.SideBar-link {
  position: relative;
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  padding: 9px 30px;
  text-align: left;
  cursor: pointer;
  color: #222222;
  background-color: #dddddd;
}

.SideBar-tab {
  margin-right: 16px;
  padding: 8px 2px;
  background-color: transparent;
}

.SideBar-tab-current {
  border-bottom: 2px solid #000;
  background-color: transparent;
}

.SideBar-tab-content {
  position: absolute;
  transition: opacity 0.2s ease;
  opacity: 0;
  z-index: 0;
}

.SideBar-tab-content-active {
  position: absolute;
  transition: opacity 0.2s ease 0.2s;
  opacity: 1;
  z-index: 100;
}

.SideBar-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 8px 16px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 4px;
  background-color: #000;
  color: #fff;
  font-weight: 700;
  width: 100%;
}

.SideBar-button:hover {
  background-color: #6623d1;
  color: #fff;
  text-decoration: none;
}

.SideBar-source-block {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
}

.SideBar-source-block .logo {
  vertical-align: middle;
  max-width: 100%;
  width: 22px;
  height: 22px;
}

.SideBar-source-block .link {
  padding-left: 8px;
}

.SideBar-source-block a {
  display: flex;
  align-items: center;
}

.SideBar-theme-grid {
  width: 100%;
  margin-bottom: 8px;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto auto;
  grid-template-rows: auto auto auto;
  display: grid;
  grid-auto-columns: 1fr;
}

@media (max-width: 767px) {
  .SideBar-theme-grid {
    width: 100%;
    grid-auto-flow: row;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }
}

.SideBar-color-picker-input-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.SideBar-color-picker-label {
  display: block;
  min-width: 50px;
  margin-bottom: 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-weight: 600;
}

.SideBar-color-picker-field {
  max-width: 100px;
  margin-bottom: 0px;
  border-radius: 4px;
  display: block;
  width: 100%;
  height: 38px;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333333;
  vertical-align: middle;
  background-color: #ffffff;
  border: 1px solid #cccccc;
}

.SideBar-option-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 12px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #f1f1f1;
  border-radius: 8px;
}

.SideBar-option-block:hover {
  background-color: #f5f5f5;
}

.SideBar-option-block:active {
  border-color: #000;
}

.SideBar-option-block:focus {
  border-style: solid;
  border-width: 1px;
  border-color: #6623d1;
}

.SideBar-option {
  width: 20px;
  height: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: #b3b3b3;
  border-radius: 20px;
  background-color: #1d1d1d;
}

.SideBar-option.accent {
  background-color: #ff6161;
}

.SideBar-option.background {
  background-color: transparent;
}

.SideBar-option.checkerboard-bg {
  background-color: transparent;
  background-image: url(opacity-pattern.png);
  background-position: 0px 0px;
  background-size: 8px;
}

.SideBar-theme-header {
  padding-bottom: 8px;
}

.SideBar-theme-container {
  margin-bottom: 16px;
}

@media (max-width: 767px) {
  .SideBar-theme-container {
    display: block;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

a {
  color: #000;
  text-decoration: none;
}

a:hover {
  color: #6623d1;
  text-decoration: none;
}

.SideBar-author-block {
  display: flex;
  flex-direction: column;
}

.SideBar-author-block a {
  padding-bottom: 8px;
}

@media (max-width: 767px) {
  .SideBar-author-block {
    display: none;
  }
}

.SideBar-author-block a {
  display: inline-block;
}

.SideBar-author-block .icon {
  vertical-align: middle;
}

.SideBar-author-block .link {
  padding-left: 8px;
}
