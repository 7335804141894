.ColorPicker-button {
  width: 20px;
  height: 20px;
  margin: 0px 8px;
  padding: 0px;
  border-style: solid;
  border-width: 1px;
  border-color: #b3b3b3;
  border-radius: 100px;
  background-image: url(opacity-pattern.png);
  background-position: 0px 0px;
  background-size: 8px;
  overflow: hidden;
}

.ColorPicker-button-active {
  border-color: #555;
}

.ColorPicker-button-color {
  width: 100%;
  height: 100%;
}

.ColorPicker-anchor {
  position: relative;
  z-index: 1000;
}

.ColorPicker-popover {
  position: absolute;
  top: 20px;
  z-index: 1000;
}

.ColorPicker-cover {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
